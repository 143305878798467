<template>
  <div>
    <b-modal size="xl" hide-footer v-model="modalReservations" class="w-100" title="Reservas de Hoy">
      <b-row>

        <b-col sm="12" md="8"></b-col>
        <b-col sm="12" md="4">
          <b-input-group>
            <b-form-input type="search" placeholder="Buscar" v-model="search" class="form-control"></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="ListReservations">
                <b-icon icon="search"></b-icon
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-col md="12">
          <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="10%" class="text-center">Nro Reserva</th>
                    <th width="45%" class="text-center">Cliente</th>
                    <th width="15%" class="text-center">Mesa</th>
                    <th width="5%" class="text-center">Hora</th>
                    <th width="8%" class="text-center">Personas</th>
                    <th width="7%" class="text-center">Adelanto</th>
                    <th width="7%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="item.id_input">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.date }}</td>
                    <td class="text-center"> {{ item.code }}</td>
                    <td class="text-left"> {{ item.client_name + " - " +item.client_document_number}}</td>
                    <td class="text-left"> {{ item.room_name + " - "+ item.table_name }}</td>
                    <td class="text-center"> {{ item.hour }}</td>
                    <td class="text-left"> {{ item.persons }}</td>
                    <td class="text-right"> {{ item.paid }}</td>
                    <td class="text-center">
                      <b-button type="button" @click="ViewReservation(item.id_reservation)" variant="primary" size="sm"><i class="fas fa-eye"></i></b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col md="8">
          <b-pagination v-model="currentPage" v-on:input="ListReservations" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
        </b-col>
        <b-col md="4 text-center">
          <p>Pagina Actual: {{ currentPage }}</p>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<style>



</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";
export default {
  name: "ModalsProduct",
  data() {
    return {
        modalReservations:false,
        module: 'Reservation',
        perPage: 15,
        currentPage: 1,
        rows: 0,
        data_table: [],
        role:2,
        search:'',
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalReservationShow', () => {
      this.modalReservations = true;
      this.role = 1;
      this.ListReservations();
    });
    
  },
  methods: {
      ListReservations,
      ViewReservation,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ListReservations() {

  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url = this.url_base + "reservation/list-reservations/"+ search + "?page=" + this.currentPage;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module,role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}
function ViewReservation(id_reservation) {
  this.$router.push({
    name: "ReservationView",
    params: { id_reservation: je.encrypt(id_reservation) },
  });
}

</script>
